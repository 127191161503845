import React from 'react'
import styled from 'styled-components'

const SnowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
  width: 100%;
  height: 100%;

  .canvas {
    width: 100%;
    height: 100%;
    /* background: radial-gradient(ellipse at center, #0a0025 0%, #000000 100%); */
    overflow: hidden;
    filter: drop-shadow(0 0 10px white);
  }

  .snow {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
  }
  .snow:nth-child(1) {
    opacity: 0.6064;
    transform: translate(56.5936vw, -10px) scale(0.1606);
    animation: fall-1 22s -16s linear infinite;
  }
  @keyframes fall-1 {
    34.585% {
      transform: translate(57.9903vw, 34.585vh) scale(0.1606);
    }
    to {
      transform: translate(57.29195vw, 150vh) scale(0.1606);
    }
  }
  .snow:nth-child(2) {
    opacity: 0.0623;
    transform: translate(38.6106vw, -10px) scale(0.3982);
    animation: fall-2 17s -21s linear infinite;
  }
  @keyframes fall-2 {
    70.443% {
      transform: translate(37.0175vw, 70.443vh) scale(0.3982);
    }
    to {
      transform: translate(37.81405vw, 150vh) scale(0.3982);
    }
  }
  .snow:nth-child(3) {
    opacity: 0.7242;
    transform: translate(18.1453vw, -10px) scale(0.9984);
    animation: fall-3 10s -24s linear infinite;
  }
  @keyframes fall-3 {
    71.78% {
      transform: translate(22.1161vw, 71.78vh) scale(0.9984);
    }
    to {
      transform: translate(20.1307vw, 150vh) scale(0.9984);
    }
  }
  .snow:nth-child(4) {
    opacity: 0.7166;
    transform: translate(59.7326vw, -10px) scale(0.0443);
    animation: fall-4 26s -3s linear infinite;
  }
  @keyframes fall-4 {
    73% {
      transform: translate(51.3509vw, 73vh) scale(0.0443);
    }
    to {
      transform: translate(55.54175vw, 150vh) scale(0.0443);
    }
  }
  .snow:nth-child(5) {
    opacity: 0.4818;
    transform: translate(47.3488vw, -10px) scale(0.0922);
    animation: fall-5 20s -19s linear infinite;
  }
  @keyframes fall-5 {
    38.705% {
      transform: translate(42.8013vw, 38.705vh) scale(0.0922);
    }
    to {
      transform: translate(45.07505vw, 150vh) scale(0.0922);
    }
  }
  .snow:nth-child(6) {
    opacity: 0.6966;
    transform: translate(82.0968vw, -10px) scale(0.2105);
    animation: fall-6 16s -19s linear infinite;
  }
  @keyframes fall-6 {
    57.687% {
      transform: translate(83.8295vw, 57.687vh) scale(0.2105);
    }
    to {
      transform: translate(82.96315vw, 150vh) scale(0.2105);
    }
  }
  .snow:nth-child(7) {
    opacity: 0.1583;
    transform: translate(30.6433vw, -10px) scale(0.633);
    animation: fall-7 17s -27s linear infinite;
  }
  @keyframes fall-7 {
    32.969% {
      transform: translate(22.2786vw, 32.969vh) scale(0.633);
    }
    to {
      transform: translate(26.46095vw, 150vh) scale(0.633);
    }
  }
  .snow:nth-child(8) {
    opacity: 0.581;
    transform: translate(11.9714vw, -10px) scale(0.9446);
    animation: fall-8 10s -19s linear infinite;
  }
  @keyframes fall-8 {
    50.353% {
      transform: translate(6.2107vw, 50.353vh) scale(0.9446);
    }
    to {
      transform: translate(9.09105vw, 150vh) scale(0.9446);
    }
  }
  .snow:nth-child(9) {
    opacity: 0.5241;
    transform: translate(81.6951vw, -10px) scale(0.0775);
    animation: fall-9 13s -8s linear infinite;
  }
  @keyframes fall-9 {
    57.565% {
      transform: translate(74.2251vw, 57.565vh) scale(0.0775);
    }
    to {
      transform: translate(77.9601vw, 150vh) scale(0.0775);
    }
  }
  .snow:nth-child(10) {
    opacity: 0.7246;
    transform: translate(25.7857vw, -10px) scale(0.9998);
    animation: fall-10 24s -25s linear infinite;
  }
  @keyframes fall-10 {
    51.74% {
      transform: translate(31.8345vw, 51.74vh) scale(0.9998);
    }
    to {
      transform: translate(28.8101vw, 150vh) scale(0.9998);
    }
  }
  .snow:nth-child(11) {
    opacity: 0.5149;
    transform: translate(92.3636vw, -10px) scale(0.0578);
    animation: fall-11 27s -18s linear infinite;
  }
  @keyframes fall-11 {
    36.667% {
      transform: translate(99.7436vw, 36.667vh) scale(0.0578);
    }
    to {
      transform: translate(96.0536vw, 150vh) scale(0.0578);
    }
  }
  .snow:nth-child(12) {
    opacity: 0.9127;
    transform: translate(49.0414vw, -10px) scale(0.8844);
    animation: fall-12 16s -10s linear infinite;
  }
  @keyframes fall-12 {
    78.832% {
      transform: translate(54.1785vw, 78.832vh) scale(0.8844);
    }
    to {
      transform: translate(51.60995vw, 150vh) scale(0.8844);
    }
  }
  .snow:nth-child(13) {
    opacity: 0.3398;
    transform: translate(69.7404vw, -10px) scale(0.1702);
    animation: fall-13 11s -27s linear infinite;
  }
  @keyframes fall-13 {
    75.387% {
      transform: translate(66.2703vw, 75.387vh) scale(0.1702);
    }
    to {
      transform: translate(68.00535vw, 150vh) scale(0.1702);
    }
  }
  .snow:nth-child(14) {
    opacity: 0.2942;
    transform: translate(78.2498vw, -10px) scale(0.2558);
    animation: fall-14 18s -4s linear infinite;
  }
  @keyframes fall-14 {
    50.833% {
      transform: translate(77.4161vw, 50.833vh) scale(0.2558);
    }
    to {
      transform: translate(77.83295vw, 150vh) scale(0.2558);
    }
  }
  .snow:nth-child(15) {
    opacity: 0.9913;
    transform: translate(33.4796vw, -10px) scale(0.971);
    animation: fall-15 18s -4s linear infinite;
  }
  @keyframes fall-15 {
    68.342% {
      transform: translate(29.8375vw, 68.342vh) scale(0.971);
    }
    to {
      transform: translate(31.65855vw, 150vh) scale(0.971);
    }
  }
  .snow:nth-child(16) {
    opacity: 0.7887;
    transform: translate(82.2204vw, -10px) scale(0.4834);
    animation: fall-16 14s -27s linear infinite;
  }
  @keyframes fall-16 {
    54.051% {
      transform: translate(73.4595vw, 54.051vh) scale(0.4834);
    }
    to {
      transform: translate(77.83995vw, 150vh) scale(0.4834);
    }
  }
  .snow:nth-child(17) {
    opacity: 0.2261;
    transform: translate(63.7127vw, -10px) scale(0.2669);
    animation: fall-17 16s -12s linear infinite;
  }
  @keyframes fall-17 {
    59.314% {
      transform: translate(67.0833vw, 59.314vh) scale(0.2669);
    }
    to {
      transform: translate(65.398vw, 150vh) scale(0.2669);
    }
  }
  .snow:nth-child(18) {
    opacity: 0.2058;
    transform: translate(52.4074vw, -10px) scale(0.5879);
    animation: fall-18 22s -9s linear infinite;
  }
  @keyframes fall-18 {
    66.43% {
      transform: translate(48.9808vw, 66.43vh) scale(0.5879);
    }
    to {
      transform: translate(50.6941vw, 150vh) scale(0.5879);
    }
  }
  .snow:nth-child(19) {
    opacity: 0.6578;
    transform: translate(6.4349vw, -10px) scale(0.4305);
    animation: fall-19 17s -1s linear infinite;
  }
  @keyframes fall-19 {
    69.544% {
      transform: translate(15.8105vw, 69.544vh) scale(0.4305);
    }
    to {
      transform: translate(11.1227vw, 150vh) scale(0.4305);
    }
  }
  .snow:nth-child(20) {
    opacity: 0.3662;
    transform: translate(25.27vw, -10px) scale(0.3203);
    animation: fall-20 13s -27s linear infinite;
  }
  @keyframes fall-20 {
    68.355% {
      transform: translate(30.7754vw, 68.355vh) scale(0.3203);
    }
    to {
      transform: translate(28.0227vw, 150vh) scale(0.3203);
    }
  }
  .snow:nth-child(21) {
    opacity: 0.9667;
    transform: translate(47.2831vw, -10px) scale(0.4046);
    animation: fall-21 11s -9s linear infinite;
  }
  @keyframes fall-21 {
    43.906% {
      transform: translate(56.1966vw, 43.906vh) scale(0.4046);
    }
    to {
      transform: translate(51.73985vw, 150vh) scale(0.4046);
    }
  }
  .snow:nth-child(22) {
    opacity: 0.2889;
    transform: translate(42.054vw, -10px) scale(0.453);
    animation: fall-22 30s -8s linear infinite;
  }
  @keyframes fall-22 {
    79.61% {
      transform: translate(38.1079vw, 79.61vh) scale(0.453);
    }
    to {
      transform: translate(40.08095vw, 150vh) scale(0.453);
    }
  }
  .snow:nth-child(23) {
    opacity: 0.7727;
    transform: translate(94.2838vw, -10px) scale(0.2761);
    animation: fall-23 18s -8s linear infinite;
  }
  @keyframes fall-23 {
    37.825% {
      transform: translate(102.4486vw, 37.825vh) scale(0.2761);
    }
    to {
      transform: translate(98.3662vw, 150vh) scale(0.2761);
    }
  }
  .snow:nth-child(24) {
    opacity: 0.1066;
    transform: translate(39.0104vw, -10px) scale(0.9023);
    animation: fall-24 14s -20s linear infinite;
  }
  @keyframes fall-24 {
    61.656% {
      transform: translate(38.537vw, 61.656vh) scale(0.9023);
    }
    to {
      transform: translate(38.7737vw, 150vh) scale(0.9023);
    }
  }
  .snow:nth-child(25) {
    opacity: 0.2742;
    transform: translate(21.0901vw, -10px) scale(0.0122);
    animation: fall-25 18s -11s linear infinite;
  }
  @keyframes fall-25 {
    44.41% {
      transform: translate(28.6667vw, 44.41vh) scale(0.0122);
    }
    to {
      transform: translate(24.8784vw, 150vh) scale(0.0122);
    }
  }
  .snow:nth-child(26) {
    opacity: 0.9528;
    transform: translate(27.8171vw, -10px) scale(0.4968);
    animation: fall-26 20s -12s linear infinite;
  }
  @keyframes fall-26 {
    48.391% {
      transform: translate(26.7582vw, 48.391vh) scale(0.4968);
    }
    to {
      transform: translate(27.28765vw, 150vh) scale(0.4968);
    }
  }
  .snow:nth-child(27) {
    opacity: 0.8532;
    transform: translate(46.2107vw, -10px) scale(0.055);
    animation: fall-27 11s -29s linear infinite;
  }
  @keyframes fall-27 {
    37.526% {
      transform: translate(40.2169vw, 37.526vh) scale(0.055);
    }
    to {
      transform: translate(43.2138vw, 150vh) scale(0.055);
    }
  }
  .snow:nth-child(28) {
    opacity: 0.6639;
    transform: translate(82.2147vw, -10px) scale(0.4175);
    animation: fall-28 12s -29s linear infinite;
  }
  @keyframes fall-28 {
    67.938% {
      transform: translate(80.9578vw, 67.938vh) scale(0.4175);
    }
    to {
      transform: translate(81.58625vw, 150vh) scale(0.4175);
    }
  }
  .snow:nth-child(29) {
    opacity: 0.0234;
    transform: translate(85.2143vw, -10px) scale(0.1361);
    animation: fall-29 24s -28s linear infinite;
  }
  @keyframes fall-29 {
    54.599% {
      transform: translate(90.5724vw, 54.599vh) scale(0.1361);
    }
    to {
      transform: translate(87.89335vw, 150vh) scale(0.1361);
    }
  }
  .snow:nth-child(30) {
    opacity: 0.3478;
    transform: translate(40.1505vw, -10px) scale(0.8939);
    animation: fall-30 21s -19s linear infinite;
  }
  @keyframes fall-30 {
    32.248% {
      transform: translate(38.2824vw, 32.248vh) scale(0.8939);
    }
    to {
      transform: translate(39.21645vw, 150vh) scale(0.8939);
    }
  }
  .snow:nth-child(31) {
    opacity: 0.9036;
    transform: translate(80.2156vw, -10px) scale(0.1224);
    animation: fall-31 27s -20s linear infinite;
  }
  @keyframes fall-31 {
    74.255% {
      transform: translate(87.1581vw, 74.255vh) scale(0.1224);
    }
    to {
      transform: translate(83.68685vw, 150vh) scale(0.1224);
    }
  }
  .snow:nth-child(32) {
    opacity: 0.1539;
    transform: translate(84.4031vw, -10px) scale(0.8212);
    animation: fall-32 21s -12s linear infinite;
  }
  @keyframes fall-32 {
    72.907% {
      transform: translate(90.9293vw, 72.907vh) scale(0.8212);
    }
    to {
      transform: translate(87.6662vw, 150vh) scale(0.8212);
    }
  }
  .snow:nth-child(33) {
    opacity: 0.2517;
    transform: translate(6.5781vw, -10px) scale(0.5607);
    animation: fall-33 17s -28s linear infinite;
  }
  @keyframes fall-33 {
    55.111% {
      transform: translate(8.9221vw, 55.111vh) scale(0.5607);
    }
    to {
      transform: translate(7.7501vw, 150vh) scale(0.5607);
    }
  }
  .snow:nth-child(34) {
    opacity: 0.4501;
    transform: translate(21.1792vw, -10px) scale(0.5248);
    animation: fall-34 13s -2s linear infinite;
  }
  @keyframes fall-34 {
    31.071% {
      transform: translate(14.8032vw, 31.071vh) scale(0.5248);
    }
    to {
      transform: translate(17.9912vw, 150vh) scale(0.5248);
    }
  }
  .snow:nth-child(35) {
    opacity: 0.0339;
    transform: translate(65.657vw, -10px) scale(0.464);
    animation: fall-35 30s -20s linear infinite;
  }
  @keyframes fall-35 {
    75.576% {
      transform: translate(71.7128vw, 75.576vh) scale(0.464);
    }
    to {
      transform: translate(68.6849vw, 150vh) scale(0.464);
    }
  }
  .snow:nth-child(36) {
    opacity: 0.1276;
    transform: translate(45.0635vw, -10px) scale(0.559);
    animation: fall-36 13s -30s linear infinite;
  }
  @keyframes fall-36 {
    46.226% {
      transform: translate(49.2227vw, 46.226vh) scale(0.559);
    }
    to {
      transform: translate(47.1431vw, 150vh) scale(0.559);
    }
  }
  .snow:nth-child(37) {
    opacity: 0.486;
    transform: translate(26.9647vw, -10px) scale(0.8743);
    animation: fall-37 18s -5s linear infinite;
  }
  @keyframes fall-37 {
    70.658% {
      transform: translate(19.0636vw, 70.658vh) scale(0.8743);
    }
    to {
      transform: translate(23.01415vw, 150vh) scale(0.8743);
    }
  }
  .snow:nth-child(38) {
    opacity: 0.3881;
    transform: translate(36.5866vw, -10px) scale(0.4045);
    animation: fall-38 18s -12s linear infinite;
  }
  @keyframes fall-38 {
    50.331% {
      transform: translate(29.0733vw, 50.331vh) scale(0.4045);
    }
    to {
      transform: translate(32.82995vw, 150vh) scale(0.4045);
    }
  }
  .snow:nth-child(39) {
    opacity: 0.5738;
    transform: translate(68.9199vw, -10px) scale(0.9079);
    animation: fall-39 13s -25s linear infinite;
  }
  @keyframes fall-39 {
    44.464% {
      transform: translate(59.2672vw, 44.464vh) scale(0.9079);
    }
    to {
      transform: translate(64.09355vw, 150vh) scale(0.9079);
    }
  }
  .snow:nth-child(40) {
    opacity: 0.8051;
    transform: translate(41.5804vw, -10px) scale(0.2563);
    animation: fall-40 23s -9s linear infinite;
  }
  @keyframes fall-40 {
    36.71% {
      transform: translate(40.8262vw, 36.71vh) scale(0.2563);
    }
    to {
      transform: translate(41.2033vw, 150vh) scale(0.2563);
    }
  }
  .snow:nth-child(41) {
    opacity: 0.5795;
    transform: translate(23.5562vw, -10px) scale(0.5648);
    animation: fall-41 15s -26s linear infinite;
  }
  @keyframes fall-41 {
    77.492% {
      transform: translate(30.6245vw, 77.492vh) scale(0.5648);
    }
    to {
      transform: translate(27.09035vw, 150vh) scale(0.5648);
    }
  }
  .snow:nth-child(42) {
    opacity: 0.3249;
    transform: translate(60.5164vw, -10px) scale(0.2912);
    animation: fall-42 18s -2s linear infinite;
  }
  @keyframes fall-42 {
    58.099% {
      transform: translate(58.7125vw, 58.099vh) scale(0.2912);
    }
    to {
      transform: translate(59.61445vw, 150vh) scale(0.2912);
    }
  }
  .snow:nth-child(43) {
    opacity: 0.8615;
    transform: translate(66.5416vw, -10px) scale(0.4002);
    animation: fall-43 21s -5s linear infinite;
  }
  @keyframes fall-43 {
    46.714% {
      transform: translate(66.6631vw, 46.714vh) scale(0.4002);
    }
    to {
      transform: translate(66.60235vw, 150vh) scale(0.4002);
    }
  }
  .snow:nth-child(44) {
    opacity: 0.1714;
    transform: translate(82.5693vw, -10px) scale(0.4522);
    animation: fall-44 15s -29s linear infinite;
  }
  @keyframes fall-44 {
    43.05% {
      transform: translate(73.9236vw, 43.05vh) scale(0.4522);
    }
    to {
      transform: translate(78.24645vw, 150vh) scale(0.4522);
    }
  }
  .snow:nth-child(45) {
    opacity: 0.9257;
    transform: translate(66.0937vw, -10px) scale(0.1823);
    animation: fall-45 24s -20s linear infinite;
  }
  @keyframes fall-45 {
    75.74% {
      transform: translate(60.4165vw, 75.74vh) scale(0.1823);
    }
    to {
      transform: translate(63.2551vw, 150vh) scale(0.1823);
    }
  }
  .snow:nth-child(46) {
    opacity: 0.9213;
    transform: translate(7.9937vw, -10px) scale(0.2919);
    animation: fall-46 18s -18s linear infinite;
  }
  @keyframes fall-46 {
    54.824% {
      transform: translate(10.7416vw, 54.824vh) scale(0.2919);
    }
    to {
      transform: translate(9.36765vw, 150vh) scale(0.2919);
    }
  }
  .snow:nth-child(47) {
    opacity: 0.4547;
    transform: translate(22.2335vw, -10px) scale(0.9467);
    animation: fall-47 12s -17s linear infinite;
  }
  @keyframes fall-47 {
    32.897% {
      transform: translate(22.4189vw, 32.897vh) scale(0.9467);
    }
    to {
      transform: translate(22.3262vw, 150vh) scale(0.9467);
    }
  }
  .snow:nth-child(48) {
    opacity: 0.3634;
    transform: translate(84.9148vw, -10px) scale(0.131);
    animation: fall-48 26s -10s linear infinite;
  }
  @keyframes fall-48 {
    50.195% {
      transform: translate(80.6264vw, 50.195vh) scale(0.131);
    }
    to {
      transform: translate(82.7706vw, 150vh) scale(0.131);
    }
  }
  .snow:nth-child(49) {
    opacity: 0.191;
    transform: translate(37.4954vw, -10px) scale(0.3518);
    animation: fall-49 10s -8s linear infinite;
  }
  @keyframes fall-49 {
    32.707% {
      transform: translate(37.7114vw, 32.707vh) scale(0.3518);
    }
    to {
      transform: translate(37.6034vw, 150vh) scale(0.3518);
    }
  }
  .snow:nth-child(50) {
    opacity: 0.4465;
    transform: translate(23.9368vw, -10px) scale(0.5617);
    animation: fall-50 20s -2s linear infinite;
  }
  @keyframes fall-50 {
    76.161% {
      transform: translate(24.5436vw, 76.161vh) scale(0.5617);
    }
    to {
      transform: translate(24.2402vw, 150vh) scale(0.5617);
    }
  }
  .snow:nth-child(51) {
    opacity: 0.4058;
    transform: translate(94.7692vw, -10px) scale(0.2213);
    animation: fall-51 12s -4s linear infinite;
  }
  @keyframes fall-51 {
    78.217% {
      transform: translate(93.2119vw, 78.217vh) scale(0.2213);
    }
    to {
      transform: translate(93.99055vw, 150vh) scale(0.2213);
    }
  }
  .snow:nth-child(52) {
    opacity: 0.0511;
    transform: translate(91.9666vw, -10px) scale(0.1553);
    animation: fall-52 12s -27s linear infinite;
  }
  @keyframes fall-52 {
    62.641% {
      transform: translate(89.7822vw, 62.641vh) scale(0.1553);
    }
    to {
      transform: translate(90.8744vw, 150vh) scale(0.1553);
    }
  }
  .snow:nth-child(53) {
    opacity: 0.6866;
    transform: translate(35.7216vw, -10px) scale(0.5644);
    animation: fall-53 25s -14s linear infinite;
  }
  @keyframes fall-53 {
    58.894% {
      transform: translate(40.0317vw, 58.894vh) scale(0.5644);
    }
    to {
      transform: translate(37.87665vw, 150vh) scale(0.5644);
    }
  }
  .snow:nth-child(54) {
    opacity: 0.582;
    transform: translate(40.2074vw, -10px) scale(0.4455);
    animation: fall-54 14s -11s linear infinite;
  }
  @keyframes fall-54 {
    77.281% {
      transform: translate(33.1553vw, 77.281vh) scale(0.4455);
    }
    to {
      transform: translate(36.68135vw, 150vh) scale(0.4455);
    }
  }
  .snow:nth-child(55) {
    opacity: 0.7486;
    transform: translate(91.0881vw, -10px) scale(0.8975);
    animation: fall-55 22s -30s linear infinite;
  }
  @keyframes fall-55 {
    73.436% {
      transform: translate(95.8822vw, 73.436vh) scale(0.8975);
    }
    to {
      transform: translate(93.48515vw, 150vh) scale(0.8975);
    }
  }
  .snow:nth-child(56) {
    opacity: 0.9149;
    transform: translate(79.1348vw, -10px) scale(0.2446);
    animation: fall-56 13s -2s linear infinite;
  }
  @keyframes fall-56 {
    43.998% {
      transform: translate(71.3727vw, 43.998vh) scale(0.2446);
    }
    to {
      transform: translate(75.25375vw, 150vh) scale(0.2446);
    }
  }
  .snow:nth-child(57) {
    opacity: 0.7213;
    transform: translate(50.8729vw, -10px) scale(0.0504);
    animation: fall-57 25s -16s linear infinite;
  }
  @keyframes fall-57 {
    44.422% {
      transform: translate(57.3603vw, 44.422vh) scale(0.0504);
    }
    to {
      transform: translate(54.1166vw, 150vh) scale(0.0504);
    }
  }
  .snow:nth-child(58) {
    opacity: 0.1156;
    transform: translate(63.6074vw, -10px) scale(0.5649);
    animation: fall-58 11s -1s linear infinite;
  }
  @keyframes fall-58 {
    62.841% {
      transform: translate(73.2332vw, 62.841vh) scale(0.5649);
    }
    to {
      transform: translate(68.4203vw, 150vh) scale(0.5649);
    }
  }
  .snow:nth-child(59) {
    opacity: 0.0688;
    transform: translate(78.3657vw, -10px) scale(0.2561);
    animation: fall-59 17s -15s linear infinite;
  }
  @keyframes fall-59 {
    43.061% {
      transform: translate(84.7611vw, 43.061vh) scale(0.2561);
    }
    to {
      transform: translate(81.5634vw, 150vh) scale(0.2561);
    }
  }
  .snow:nth-child(60) {
    opacity: 0.8235;
    transform: translate(34.8727vw, -10px) scale(0.1058);
    animation: fall-60 23s -21s linear infinite;
  }
  @keyframes fall-60 {
    74.842% {
      transform: translate(31.7602vw, 74.842vh) scale(0.1058);
    }
    to {
      transform: translate(33.31645vw, 150vh) scale(0.1058);
    }
  }
  .snow:nth-child(61) {
    opacity: 0.0526;
    transform: translate(3.275vw, -10px) scale(0.6346);
    animation: fall-61 11s -10s linear infinite;
  }
  @keyframes fall-61 {
    38.158% {
      transform: translate(-1.029vw, 38.158vh) scale(0.6346);
    }
    to {
      transform: translate(1.123vw, 150vh) scale(0.6346);
    }
  }
  .snow:nth-child(62) {
    opacity: 0.8859;
    transform: translate(36.1158vw, -10px) scale(0.1344);
    animation: fall-62 22s -21s linear infinite;
  }
  @keyframes fall-62 {
    62.332% {
      transform: translate(33.6984vw, 62.332vh) scale(0.1344);
    }
    to {
      transform: translate(34.9071vw, 150vh) scale(0.1344);
    }
  }
  .snow:nth-child(63) {
    opacity: 0.1977;
    transform: translate(33.3671vw, -10px) scale(0.0632);
    animation: fall-63 15s -25s linear infinite;
  }
  @keyframes fall-63 {
    37.64% {
      transform: translate(43.0982vw, 37.64vh) scale(0.0632);
    }
    to {
      transform: translate(38.23265vw, 150vh) scale(0.0632);
    }
  }
  .snow:nth-child(64) {
    opacity: 0.6276;
    transform: translate(23.4037vw, -10px) scale(0.3789);
    animation: fall-64 29s -27s linear infinite;
  }
  @keyframes fall-64 {
    68.776% {
      transform: translate(18.5666vw, 68.776vh) scale(0.3789);
    }
    to {
      transform: translate(20.98515vw, 150vh) scale(0.3789);
    }
  }
  .snow:nth-child(65) {
    opacity: 0.7737;
    transform: translate(18.3928vw, -10px) scale(0.348);
    animation: fall-65 14s -26s linear infinite;
  }
  @keyframes fall-65 {
    79.243% {
      transform: translate(11.1482vw, 79.243vh) scale(0.348);
    }
    to {
      transform: translate(14.7705vw, 150vh) scale(0.348);
    }
  }
  .snow:nth-child(66) {
    opacity: 0.1562;
    transform: translate(23.4487vw, -10px) scale(0.4969);
    animation: fall-66 18s -7s linear infinite;
  }
  @keyframes fall-66 {
    70.921% {
      transform: translate(13.5305vw, 70.921vh) scale(0.4969);
    }
    to {
      transform: translate(18.4896vw, 150vh) scale(0.4969);
    }
  }
  .snow:nth-child(67) {
    opacity: 0.9507;
    transform: translate(8.2918vw, -10px) scale(0.8008);
    animation: fall-67 26s -6s linear infinite;
  }
  @keyframes fall-67 {
    41.042% {
      transform: translate(4.6277vw, 41.042vh) scale(0.8008);
    }
    to {
      transform: translate(6.45975vw, 150vh) scale(0.8008);
    }
  }
  .snow:nth-child(68) {
    opacity: 0.2859;
    transform: translate(4.4022vw, -10px) scale(0.4319);
    animation: fall-68 23s -11s linear infinite;
  }
  @keyframes fall-68 {
    30.449% {
      transform: translate(13.092vw, 30.449vh) scale(0.4319);
    }
    to {
      transform: translate(8.7471vw, 150vh) scale(0.4319);
    }
  }
  .snow:nth-child(69) {
    opacity: 0.9147;
    transform: translate(63.1398vw, -10px) scale(0.4155);
    animation: fall-69 28s -3s linear infinite;
  }
  @keyframes fall-69 {
    65.383% {
      transform: translate(65.1352vw, 65.383vh) scale(0.4155);
    }
    to {
      transform: translate(64.1375vw, 150vh) scale(0.4155);
    }
  }
  .snow:nth-child(70) {
    opacity: 0.8848;
    transform: translate(23.4444vw, -10px) scale(0.8146);
    animation: fall-70 13s -22s linear infinite;
  }
  @keyframes fall-70 {
    43.648% {
      transform: translate(17.8633vw, 43.648vh) scale(0.8146);
    }
    to {
      transform: translate(20.65385vw, 150vh) scale(0.8146);
    }
  }
  .snow:nth-child(71) {
    opacity: 0.7118;
    transform: translate(90.9626vw, -10px) scale(0.7588);
    animation: fall-71 18s -1s linear infinite;
  }
  @keyframes fall-71 {
    58.277% {
      transform: translate(98.2016vw, 58.277vh) scale(0.7588);
    }
    to {
      transform: translate(94.5821vw, 150vh) scale(0.7588);
    }
  }
  .snow:nth-child(72) {
    opacity: 0.5087;
    transform: translate(73.3722vw, -10px) scale(0.9993);
    animation: fall-72 18s -6s linear infinite;
  }
  @keyframes fall-72 {
    52.181% {
      transform: translate(72.7816vw, 52.181vh) scale(0.9993);
    }
    to {
      transform: translate(73.0769vw, 150vh) scale(0.9993);
    }
  }
  .snow:nth-child(73) {
    opacity: 0.9855;
    transform: translate(79.5455vw, -10px) scale(0.6813);
    animation: fall-73 21s -22s linear infinite;
  }
  @keyframes fall-73 {
    58.651% {
      transform: translate(81.0418vw, 58.651vh) scale(0.6813);
    }
    to {
      transform: translate(80.29365vw, 150vh) scale(0.6813);
    }
  }
  .snow:nth-child(74) {
    opacity: 0.5779;
    transform: translate(0.8084vw, -10px) scale(0.8054);
    animation: fall-74 29s -2s linear infinite;
  }
  @keyframes fall-74 {
    73.29% {
      transform: translate(-2.6132vw, 73.29vh) scale(0.8054);
    }
    to {
      transform: translate(-0.9024vw, 150vh) scale(0.8054);
    }
  }
  .snow:nth-child(75) {
    opacity: 0.9123;
    transform: translate(84.4454vw, -10px) scale(0.4911);
    animation: fall-75 26s -9s linear infinite;
  }
  @keyframes fall-75 {
    71.691% {
      transform: translate(75.9106vw, 71.691vh) scale(0.4911);
    }
    to {
      transform: translate(80.178vw, 150vh) scale(0.4911);
    }
  }
  .snow:nth-child(76) {
    opacity: 0.879;
    transform: translate(69.2109vw, -10px) scale(0.6467);
    animation: fall-76 10s -24s linear infinite;
  }
  @keyframes fall-76 {
    40.999% {
      transform: translate(71.1344vw, 40.999vh) scale(0.6467);
    }
    to {
      transform: translate(70.17265vw, 150vh) scale(0.6467);
    }
  }
  .snow:nth-child(77) {
    opacity: 0.9983;
    transform: translate(41.5932vw, -10px) scale(0.4618);
    animation: fall-77 17s -17s linear infinite;
  }
  @keyframes fall-77 {
    47.127% {
      transform: translate(45.8938vw, 47.127vh) scale(0.4618);
    }
    to {
      transform: translate(43.7435vw, 150vh) scale(0.4618);
    }
  }
  .snow:nth-child(78) {
    opacity: 0.1497;
    transform: translate(33.1912vw, -10px) scale(0.7018);
    animation: fall-78 17s -10s linear infinite;
  }
  @keyframes fall-78 {
    69.153% {
      transform: translate(36.1319vw, 69.153vh) scale(0.7018);
    }
    to {
      transform: translate(34.66155vw, 150vh) scale(0.7018);
    }
  }
  .snow:nth-child(79) {
    opacity: 0.1377;
    transform: translate(52.5935vw, -10px) scale(0.1223);
    animation: fall-79 13s -20s linear infinite;
  }
  @keyframes fall-79 {
    62.477% {
      transform: translate(48.3592vw, 62.477vh) scale(0.1223);
    }
    to {
      transform: translate(50.47635vw, 150vh) scale(0.1223);
    }
  }
  .snow:nth-child(80) {
    opacity: 0.7409;
    transform: translate(19.2156vw, -10px) scale(0.2645);
    animation: fall-80 16s -14s linear infinite;
  }
  @keyframes fall-80 {
    75.757% {
      transform: translate(21.354vw, 75.757vh) scale(0.2645);
    }
    to {
      transform: translate(20.2848vw, 150vh) scale(0.2645);
    }
  }
  .snow:nth-child(81) {
    opacity: 0.821;
    transform: translate(3.0341vw, -10px) scale(0.6898);
    animation: fall-81 15s -13s linear infinite;
  }
  @keyframes fall-81 {
    47.696% {
      transform: translate(-5.1028vw, 47.696vh) scale(0.6898);
    }
    to {
      transform: translate(-1.03435vw, 150vh) scale(0.6898);
    }
  }
  .snow:nth-child(82) {
    opacity: 0.8718;
    transform: translate(79.0731vw, -10px) scale(0.0889);
    animation: fall-82 28s -11s linear infinite;
  }
  @keyframes fall-82 {
    51.907% {
      transform: translate(84.9244vw, 51.907vh) scale(0.0889);
    }
    to {
      transform: translate(81.99875vw, 150vh) scale(0.0889);
    }
  }
  .snow:nth-child(83) {
    opacity: 0.2257;
    transform: translate(77.5689vw, -10px) scale(0.4153);
    animation: fall-83 27s -22s linear infinite;
  }
  @keyframes fall-83 {
    45.475% {
      transform: translate(80.1411vw, 45.475vh) scale(0.4153);
    }
    to {
      transform: translate(78.855vw, 150vh) scale(0.4153);
    }
  }
  .snow:nth-child(84) {
    opacity: 0.6003;
    transform: translate(38.2591vw, -10px) scale(0.1744);
    animation: fall-84 29s -13s linear infinite;
  }
  @keyframes fall-84 {
    63.595% {
      transform: translate(40.3118vw, 63.595vh) scale(0.1744);
    }
    to {
      transform: translate(39.28545vw, 150vh) scale(0.1744);
    }
  }
  .snow:nth-child(85) {
    opacity: 0.6428;
    transform: translate(43.6799vw, -10px) scale(0.7761);
    animation: fall-85 22s -6s linear infinite;
  }
  @keyframes fall-85 {
    52.47% {
      transform: translate(39.9986vw, 52.47vh) scale(0.7761);
    }
    to {
      transform: translate(41.83925vw, 150vh) scale(0.7761);
    }
  }
  .snow:nth-child(86) {
    opacity: 0.2203;
    transform: translate(11.8462vw, -10px) scale(0.4738);
    animation: fall-86 12s -27s linear infinite;
  }
  @keyframes fall-86 {
    37.162% {
      transform: translate(9.4196vw, 37.162vh) scale(0.4738);
    }
    to {
      transform: translate(10.6329vw, 150vh) scale(0.4738);
    }
  }
  .snow:nth-child(87) {
    opacity: 0.2042;
    transform: translate(68.1988vw, -10px) scale(0.3326);
    animation: fall-87 16s -2s linear infinite;
  }
  @keyframes fall-87 {
    50.915% {
      transform: translate(59.3551vw, 50.915vh) scale(0.3326);
    }
    to {
      transform: translate(63.77695vw, 150vh) scale(0.3326);
    }
  }
  .snow:nth-child(88) {
    opacity: 0.4405;
    transform: translate(66.7981vw, -10px) scale(0.143);
    animation: fall-88 15s -21s linear infinite;
  }
  @keyframes fall-88 {
    60.645% {
      transform: translate(76.7838vw, 60.645vh) scale(0.143);
    }
    to {
      transform: translate(71.79095vw, 150vh) scale(0.143);
    }
  }
  .snow:nth-child(89) {
    opacity: 0.8594;
    transform: translate(90.0449vw, -10px) scale(0.7175);
    animation: fall-89 24s -24s linear infinite;
  }
  @keyframes fall-89 {
    70.519% {
      transform: translate(87.4105vw, 70.519vh) scale(0.7175);
    }
    to {
      transform: translate(88.7277vw, 150vh) scale(0.7175);
    }
  }
  .snow:nth-child(90) {
    opacity: 0.0904;
    transform: translate(81.0178vw, -10px) scale(0.954);
    animation: fall-90 21s -14s linear infinite;
  }
  @keyframes fall-90 {
    66.779% {
      transform: translate(73.5897vw, 66.779vh) scale(0.954);
    }
    to {
      transform: translate(77.30375vw, 150vh) scale(0.954);
    }
  }
  .snow:nth-child(91) {
    opacity: 0.1728;
    transform: translate(97.0289vw, -10px) scale(0.8606);
    animation: fall-91 26s -15s linear infinite;
  }
  @keyframes fall-91 {
    36.443% {
      transform: translate(87.7748vw, 36.443vh) scale(0.8606);
    }
    to {
      transform: translate(92.40185vw, 150vh) scale(0.8606);
    }
  }
  .snow:nth-child(92) {
    opacity: 0.2311;
    transform: translate(5.3531vw, -10px) scale(0.5513);
    animation: fall-92 10s -30s linear infinite;
  }
  @keyframes fall-92 {
    61.957% {
      transform: translate(4.015vw, 61.957vh) scale(0.5513);
    }
    to {
      transform: translate(4.68405vw, 150vh) scale(0.5513);
    }
  }
  .snow:nth-child(93) {
    opacity: 0.384;
    transform: translate(95.485vw, -10px) scale(0.6726);
    animation: fall-93 27s -6s linear infinite;
  }
  @keyframes fall-93 {
    46.183% {
      transform: translate(91.9757vw, 46.183vh) scale(0.6726);
    }
    to {
      transform: translate(93.73035vw, 150vh) scale(0.6726);
    }
  }
  .snow:nth-child(94) {
    opacity: 0.3479;
    transform: translate(42.8071vw, -10px) scale(0.3889);
    animation: fall-94 25s -26s linear infinite;
  }
  @keyframes fall-94 {
    47.313% {
      transform: translate(41.6502vw, 47.313vh) scale(0.3889);
    }
    to {
      transform: translate(42.22865vw, 150vh) scale(0.3889);
    }
  }
  .snow:nth-child(95) {
    opacity: 0.2161;
    transform: translate(72.2974vw, -10px) scale(0.2408);
    animation: fall-95 10s -18s linear infinite;
  }
  @keyframes fall-95 {
    49.198% {
      transform: translate(73.5698vw, 49.198vh) scale(0.2408);
    }
    to {
      transform: translate(72.9336vw, 150vh) scale(0.2408);
    }
  }
  .snow:nth-child(96) {
    opacity: 0.7166;
    transform: translate(79.9389vw, -10px) scale(0.7145);
    animation: fall-96 24s -17s linear infinite;
  }
  @keyframes fall-96 {
    77.485% {
      transform: translate(85.7483vw, 77.485vh) scale(0.7145);
    }
    to {
      transform: translate(82.8436vw, 150vh) scale(0.7145);
    }
  }
  .snow:nth-child(97) {
    opacity: 0.2107;
    transform: translate(34.8164vw, -10px) scale(0.6583);
    animation: fall-97 24s -21s linear infinite;
  }
  @keyframes fall-97 {
    43.029% {
      transform: translate(44.7713vw, 43.029vh) scale(0.6583);
    }
    to {
      transform: translate(39.79385vw, 150vh) scale(0.6583);
    }
  }
  .snow:nth-child(98) {
    opacity: 0.2908;
    transform: translate(27.983vw, -10px) scale(0.7688);
    animation: fall-98 21s -6s linear infinite;
  }
  @keyframes fall-98 {
    52.02% {
      transform: translate(27.3045vw, 52.02vh) scale(0.7688);
    }
    to {
      transform: translate(27.64375vw, 150vh) scale(0.7688);
    }
  }
  .snow:nth-child(99) {
    opacity: 0.1482;
    transform: translate(14.9457vw, -10px) scale(0.0425);
    animation: fall-99 14s -29s linear infinite;
  }
  @keyframes fall-99 {
    58.215% {
      transform: translate(21.8648vw, 58.215vh) scale(0.0425);
    }
    to {
      transform: translate(18.40525vw, 150vh) scale(0.0425);
    }
  }
  .snow:nth-child(100) {
    opacity: 0.6941;
    transform: translate(4.312vw, -10px) scale(0.6985);
    animation: fall-100 16s -11s linear infinite;
  }
  @keyframes fall-100 {
    64.887% {
      transform: translate(-1.5017vw, 64.887vh) scale(0.6985);
    }
    to {
      transform: translate(1.40515vw, 150vh) scale(0.6985);
    }
  }
  .snow:nth-child(101) {
    opacity: 0.2655;
    transform: translate(9.9107vw, -10px) scale(0.2117);
    animation: fall-101 15s -2s linear infinite;
  }
  @keyframes fall-101 {
    38.315% {
      transform: translate(4.9562vw, 38.315vh) scale(0.2117);
    }
    to {
      transform: translate(7.43345vw, 150vh) scale(0.2117);
    }
  }
  .snow:nth-child(102) {
    opacity: 0.6411;
    transform: translate(35.7989vw, -10px) scale(0.0677);
    animation: fall-102 12s -2s linear infinite;
  }
  @keyframes fall-102 {
    40.935% {
      transform: translate(31.9013vw, 40.935vh) scale(0.0677);
    }
    to {
      transform: translate(33.8501vw, 150vh) scale(0.0677);
    }
  }
  .snow:nth-child(103) {
    opacity: 0.173;
    transform: translate(16.3894vw, -10px) scale(0.9221);
    animation: fall-103 15s -23s linear infinite;
  }
  @keyframes fall-103 {
    47.967% {
      transform: translate(20.624vw, 47.967vh) scale(0.9221);
    }
    to {
      transform: translate(18.5067vw, 150vh) scale(0.9221);
    }
  }
  .snow:nth-child(104) {
    opacity: 0.5854;
    transform: translate(40.164vw, -10px) scale(0.8623);
    animation: fall-104 28s -28s linear infinite;
  }
  @keyframes fall-104 {
    33.038% {
      transform: translate(42.974vw, 33.038vh) scale(0.8623);
    }
    to {
      transform: translate(41.569vw, 150vh) scale(0.8623);
    }
  }
  .snow:nth-child(105) {
    opacity: 0.4442;
    transform: translate(72.5666vw, -10px) scale(0.889);
    animation: fall-105 28s -21s linear infinite;
  }
  @keyframes fall-105 {
    30.243% {
      transform: translate(67.1893vw, 30.243vh) scale(0.889);
    }
    to {
      transform: translate(69.87795vw, 150vh) scale(0.889);
    }
  }
  .snow:nth-child(106) {
    opacity: 0.9278;
    transform: translate(2.9024vw, -10px) scale(0.9838);
    animation: fall-106 17s -19s linear infinite;
  }
  @keyframes fall-106 {
    48.778% {
      transform: translate(10.957vw, 48.778vh) scale(0.9838);
    }
    to {
      transform: translate(6.9297vw, 150vh) scale(0.9838);
    }
  }
  .snow:nth-child(107) {
    opacity: 0.2946;
    transform: translate(84.6703vw, -10px) scale(0.1176);
    animation: fall-107 26s -27s linear infinite;
  }
  @keyframes fall-107 {
    47.771% {
      transform: translate(87.3362vw, 47.771vh) scale(0.1176);
    }
    to {
      transform: translate(86.00325vw, 150vh) scale(0.1176);
    }
  }
  .snow:nth-child(108) {
    opacity: 0.7511;
    transform: translate(54.1105vw, -10px) scale(0.7076);
    animation: fall-108 17s -8s linear infinite;
  }
  @keyframes fall-108 {
    37.796% {
      transform: translate(48.0652vw, 37.796vh) scale(0.7076);
    }
    to {
      transform: translate(51.08785vw, 150vh) scale(0.7076);
    }
  }
  .snow:nth-child(109) {
    opacity: 0.2139;
    transform: translate(60.61vw, -10px) scale(0.2164);
    animation: fall-109 20s -17s linear infinite;
  }
  @keyframes fall-109 {
    71.703% {
      transform: translate(66.6319vw, 71.703vh) scale(0.2164);
    }
    to {
      transform: translate(63.62095vw, 150vh) scale(0.2164);
    }
  }
  .snow:nth-child(110) {
    opacity: 0.0409;
    transform: translate(24.236vw, -10px) scale(0.0995);
    animation: fall-110 11s -28s linear infinite;
  }
  @keyframes fall-110 {
    54.433% {
      transform: translate(18.0408vw, 54.433vh) scale(0.0995);
    }
    to {
      transform: translate(21.1384vw, 150vh) scale(0.0995);
    }
  }
  .snow:nth-child(111) {
    opacity: 0.0888;
    transform: translate(16.9777vw, -10px) scale(0.4807);
    animation: fall-111 14s -17s linear infinite;
  }
  @keyframes fall-111 {
    32.457% {
      transform: translate(20.8533vw, 32.457vh) scale(0.4807);
    }
    to {
      transform: translate(18.9155vw, 150vh) scale(0.4807);
    }
  }
  .snow:nth-child(112) {
    opacity: 0.5612;
    transform: translate(16.2678vw, -10px) scale(0.133);
    animation: fall-112 27s -21s linear infinite;
  }
  @keyframes fall-112 {
    52.456% {
      transform: translate(10.5542vw, 52.456vh) scale(0.133);
    }
    to {
      transform: translate(13.411vw, 150vh) scale(0.133);
    }
  }
  .snow:nth-child(113) {
    opacity: 0.8734;
    transform: translate(2.9566vw, -10px) scale(0.8338);
    animation: fall-113 29s -20s linear infinite;
  }
  @keyframes fall-113 {
    49.923% {
      transform: translate(7.0474vw, 49.923vh) scale(0.8338);
    }
    to {
      transform: translate(5.002vw, 150vh) scale(0.8338);
    }
  }
  .snow:nth-child(114) {
    opacity: 0.8291;
    transform: translate(14.99vw, -10px) scale(0.2383);
    animation: fall-114 11s -7s linear infinite;
  }
  @keyframes fall-114 {
    49.147% {
      transform: translate(12.1948vw, 49.147vh) scale(0.2383);
    }
    to {
      transform: translate(13.5924vw, 150vh) scale(0.2383);
    }
  }
  .snow:nth-child(115) {
    opacity: 0.9135;
    transform: translate(93.2031vw, -10px) scale(0.4367);
    animation: fall-115 25s -12s linear infinite;
  }
  @keyframes fall-115 {
    48.929% {
      transform: translate(89.9588vw, 48.929vh) scale(0.4367);
    }
    to {
      transform: translate(91.58095vw, 150vh) scale(0.4367);
    }
  }
  .snow:nth-child(116) {
    opacity: 0.6152;
    transform: translate(54.7649vw, -10px) scale(0.265);
    animation: fall-116 23s -15s linear infinite;
  }
  @keyframes fall-116 {
    42.988% {
      transform: translate(51.9764vw, 42.988vh) scale(0.265);
    }
    to {
      transform: translate(53.37065vw, 150vh) scale(0.265);
    }
  }
  .snow:nth-child(117) {
    opacity: 0.3368;
    transform: translate(41.1716vw, -10px) scale(0.2666);
    animation: fall-117 19s -29s linear infinite;
  }
  @keyframes fall-117 {
    33.44% {
      transform: translate(46.642vw, 33.44vh) scale(0.2666);
    }
    to {
      transform: translate(43.9068vw, 150vh) scale(0.2666);
    }
  }
  .snow:nth-child(118) {
    opacity: 0.4309;
    transform: translate(87.3638vw, -10px) scale(0.2669);
    animation: fall-118 17s -17s linear infinite;
  }
  @keyframes fall-118 {
    71.277% {
      transform: translate(95.757vw, 71.277vh) scale(0.2669);
    }
    to {
      transform: translate(91.5604vw, 150vh) scale(0.2669);
    }
  }
  .snow:nth-child(119) {
    opacity: 0.4446;
    transform: translate(2.1466vw, -10px) scale(0.7521);
    animation: fall-119 22s -16s linear infinite;
  }
  @keyframes fall-119 {
    50.39% {
      transform: translate(-3.2082vw, 50.39vh) scale(0.7521);
    }
    to {
      transform: translate(-0.5308vw, 150vh) scale(0.7521);
    }
  }
  .snow:nth-child(120) {
    opacity: 0.5929;
    transform: translate(51.9297vw, -10px) scale(0.353);
    animation: fall-120 22s -10s linear infinite;
  }
  @keyframes fall-120 {
    39.333% {
      transform: translate(54.8831vw, 39.333vh) scale(0.353);
    }
    to {
      transform: translate(53.4064vw, 150vh) scale(0.353);
    }
  }
  .snow:nth-child(121) {
    opacity: 0.1432;
    transform: translate(79.1121vw, -10px) scale(0.3649);
    animation: fall-121 13s -15s linear infinite;
  }
  @keyframes fall-121 {
    46.836% {
      transform: translate(72.5459vw, 46.836vh) scale(0.3649);
    }
    to {
      transform: translate(75.829vw, 150vh) scale(0.3649);
    }
  }
  .snow:nth-child(122) {
    opacity: 0.7405;
    transform: translate(87.7616vw, -10px) scale(0.9917);
    animation: fall-122 12s -12s linear infinite;
  }
  @keyframes fall-122 {
    35.435% {
      transform: translate(93.1391vw, 35.435vh) scale(0.9917);
    }
    to {
      transform: translate(90.45035vw, 150vh) scale(0.9917);
    }
  }
  .snow:nth-child(123) {
    opacity: 0.9654;
    transform: translate(19.1452vw, -10px) scale(0.6616);
    animation: fall-123 15s -21s linear infinite;
  }
  @keyframes fall-123 {
    66.58% {
      transform: translate(23.2683vw, 66.58vh) scale(0.6616);
    }
    to {
      transform: translate(21.20675vw, 150vh) scale(0.6616);
    }
  }
  .snow:nth-child(124) {
    opacity: 0.1651;
    transform: translate(75.9691vw, -10px) scale(0.0787);
    animation: fall-124 24s -24s linear infinite;
  }
  @keyframes fall-124 {
    52.105% {
      transform: translate(84.2255vw, 52.105vh) scale(0.0787);
    }
    to {
      transform: translate(80.0973vw, 150vh) scale(0.0787);
    }
  }
  .snow:nth-child(125) {
    opacity: 0.3378;
    transform: translate(99.7268vw, -10px) scale(0.8839);
    animation: fall-125 29s -17s linear infinite;
  }
  @keyframes fall-125 {
    37.426% {
      transform: translate(103.8323vw, 37.426vh) scale(0.8839);
    }
    to {
      transform: translate(101.77955vw, 150vh) scale(0.8839);
    }
  }
  .snow:nth-child(126) {
    opacity: 0.2255;
    transform: translate(38.173vw, -10px) scale(0.7555);
    animation: fall-126 24s -7s linear infinite;
  }
  @keyframes fall-126 {
    73.349% {
      transform: translate(43.6995vw, 73.349vh) scale(0.7555);
    }
    to {
      transform: translate(40.93625vw, 150vh) scale(0.7555);
    }
  }
  .snow:nth-child(127) {
    opacity: 0.1517;
    transform: translate(9.027vw, -10px) scale(0.6722);
    animation: fall-127 16s -28s linear infinite;
  }
  @keyframes fall-127 {
    66.931% {
      transform: translate(6.0099vw, 66.931vh) scale(0.6722);
    }
    to {
      transform: translate(7.51845vw, 150vh) scale(0.6722);
    }
  }
  .snow:nth-child(128) {
    opacity: 0.5383;
    transform: translate(46.003vw, -10px) scale(0.3891);
    animation: fall-128 25s -9s linear infinite;
  }
  @keyframes fall-128 {
    61.567% {
      transform: translate(50.3104vw, 61.567vh) scale(0.3891);
    }
    to {
      transform: translate(48.1567vw, 150vh) scale(0.3891);
    }
  }
  .snow:nth-child(129) {
    opacity: 0.6954;
    transform: translate(70.729vw, -10px) scale(0.1522);
    animation: fall-129 29s -7s linear infinite;
  }
  @keyframes fall-129 {
    42.384% {
      transform: translate(67.1801vw, 42.384vh) scale(0.1522);
    }
    to {
      transform: translate(68.95455vw, 150vh) scale(0.1522);
    }
  }
  .snow:nth-child(130) {
    opacity: 0.8818;
    transform: translate(0.2239vw, -10px) scale(0.5732);
    animation: fall-130 28s -4s linear infinite;
  }
  @keyframes fall-130 {
    56.322% {
      transform: translate(6.5223vw, 56.322vh) scale(0.5732);
    }
    to {
      transform: translate(3.3731vw, 150vh) scale(0.5732);
    }
  }
  .snow:nth-child(131) {
    opacity: 0.0111;
    transform: translate(16.1061vw, -10px) scale(0.2998);
    animation: fall-131 25s -9s linear infinite;
  }
  @keyframes fall-131 {
    60.491% {
      transform: translate(14.4009vw, 60.491vh) scale(0.2998);
    }
    to {
      transform: translate(15.2535vw, 150vh) scale(0.2998);
    }
  }
  .snow:nth-child(132) {
    opacity: 0.3851;
    transform: translate(73.6096vw, -10px) scale(0.1103);
    animation: fall-132 12s -10s linear infinite;
  }
  @keyframes fall-132 {
    47.914% {
      transform: translate(78.1664vw, 47.914vh) scale(0.1103);
    }
    to {
      transform: translate(75.888vw, 150vh) scale(0.1103);
    }
  }
  .snow:nth-child(133) {
    opacity: 0.2935;
    transform: translate(14.0252vw, -10px) scale(0.5924);
    animation: fall-133 13s -8s linear infinite;
  }
  @keyframes fall-133 {
    45.35% {
      transform: translate(8.1118vw, 45.35vh) scale(0.5924);
    }
    to {
      transform: translate(11.0685vw, 150vh) scale(0.5924);
    }
  }
  .snow:nth-child(134) {
    opacity: 0.968;
    transform: translate(72.3995vw, -10px) scale(0.3832);
    animation: fall-134 10s -24s linear infinite;
  }
  @keyframes fall-134 {
    35.68% {
      transform: translate(79.1341vw, 35.68vh) scale(0.3832);
    }
    to {
      transform: translate(75.7668vw, 150vh) scale(0.3832);
    }
  }
  .snow:nth-child(135) {
    opacity: 0.0304;
    transform: translate(89.5467vw, -10px) scale(0.7527);
    animation: fall-135 20s -26s linear infinite;
  }
  @keyframes fall-135 {
    34.363% {
      transform: translate(93.8397vw, 34.363vh) scale(0.7527);
    }
    to {
      transform: translate(91.6932vw, 150vh) scale(0.7527);
    }
  }
  .snow:nth-child(136) {
    opacity: 0.9148;
    transform: translate(57.3328vw, -10px) scale(0.9316);
    animation: fall-136 26s -28s linear infinite;
  }
  @keyframes fall-136 {
    42.415% {
      transform: translate(66.5063vw, 42.415vh) scale(0.9316);
    }
    to {
      transform: translate(61.91955vw, 150vh) scale(0.9316);
    }
  }
  .snow:nth-child(137) {
    opacity: 0.3371;
    transform: translate(77.8973vw, -10px) scale(0.1934);
    animation: fall-137 22s -9s linear infinite;
  }
  @keyframes fall-137 {
    30.252% {
      transform: translate(70.6699vw, 30.252vh) scale(0.1934);
    }
    to {
      transform: translate(74.2836vw, 150vh) scale(0.1934);
    }
  }
  .snow:nth-child(138) {
    opacity: 0.2501;
    transform: translate(63.3636vw, -10px) scale(0.2754);
    animation: fall-138 14s -1s linear infinite;
  }
  @keyframes fall-138 {
    60.866% {
      transform: translate(68.9816vw, 60.866vh) scale(0.2754);
    }
    to {
      transform: translate(66.1726vw, 150vh) scale(0.2754);
    }
  }
  .snow:nth-child(139) {
    opacity: 0.4896;
    transform: translate(34.4003vw, -10px) scale(0.8847);
    animation: fall-139 11s -25s linear infinite;
  }
  @keyframes fall-139 {
    79.87% {
      transform: translate(39.7115vw, 79.87vh) scale(0.8847);
    }
    to {
      transform: translate(37.0559vw, 150vh) scale(0.8847);
    }
  }
  .snow:nth-child(140) {
    opacity: 0.17;
    transform: translate(30.5296vw, -10px) scale(0.7586);
    animation: fall-140 21s -30s linear infinite;
  }
  @keyframes fall-140 {
    68.485% {
      transform: translate(34.2255vw, 68.485vh) scale(0.7586);
    }
    to {
      transform: translate(32.37755vw, 150vh) scale(0.7586);
    }
  }
  .snow:nth-child(141) {
    opacity: 0.0292;
    transform: translate(37.3772vw, -10px) scale(0.9827);
    animation: fall-141 17s -3s linear infinite;
  }
  @keyframes fall-141 {
    59.098% {
      transform: translate(41.0599vw, 59.098vh) scale(0.9827);
    }
    to {
      transform: translate(39.21855vw, 150vh) scale(0.9827);
    }
  }
  .snow:nth-child(142) {
    opacity: 0.896;
    transform: translate(55.6196vw, -10px) scale(0.0532);
    animation: fall-142 16s -6s linear infinite;
  }
  @keyframes fall-142 {
    60.633% {
      transform: translate(54.7343vw, 60.633vh) scale(0.0532);
    }
    to {
      transform: translate(55.17695vw, 150vh) scale(0.0532);
    }
  }
  .snow:nth-child(143) {
    opacity: 0.7804;
    transform: translate(60.8152vw, -10px) scale(0.9424);
    animation: fall-143 10s -16s linear infinite;
  }
  @keyframes fall-143 {
    33.254% {
      transform: translate(65.6851vw, 33.254vh) scale(0.9424);
    }
    to {
      transform: translate(63.25015vw, 150vh) scale(0.9424);
    }
  }
  .snow:nth-child(144) {
    opacity: 0.1452;
    transform: translate(89.8383vw, -10px) scale(0.1001);
    animation: fall-144 18s -23s linear infinite;
  }
  @keyframes fall-144 {
    30.391% {
      transform: translate(91.3511vw, 30.391vh) scale(0.1001);
    }
    to {
      transform: translate(90.5947vw, 150vh) scale(0.1001);
    }
  }
  .snow:nth-child(145) {
    opacity: 0.7184;
    transform: translate(48.5351vw, -10px) scale(0.3599);
    animation: fall-145 22s -22s linear infinite;
  }
  @keyframes fall-145 {
    59.345% {
      transform: translate(55.3838vw, 59.345vh) scale(0.3599);
    }
    to {
      transform: translate(51.95945vw, 150vh) scale(0.3599);
    }
  }
  .snow:nth-child(146) {
    opacity: 0.9699;
    transform: translate(35.3261vw, -10px) scale(0.4453);
    animation: fall-146 11s -1s linear infinite;
  }
  @keyframes fall-146 {
    66.551% {
      transform: translate(25.5637vw, 66.551vh) scale(0.4453);
    }
    to {
      transform: translate(30.4449vw, 150vh) scale(0.4453);
    }
  }
  .snow:nth-child(147) {
    opacity: 0.0402;
    transform: translate(98.9298vw, -10px) scale(0.7885);
    animation: fall-147 10s -9s linear infinite;
  }
  @keyframes fall-147 {
    76.874% {
      transform: translate(89.2414vw, 76.874vh) scale(0.7885);
    }
    to {
      transform: translate(94.0856vw, 150vh) scale(0.7885);
    }
  }
  .snow:nth-child(148) {
    opacity: 0.9046;
    transform: translate(80.2911vw, -10px) scale(0.7863);
    animation: fall-148 17s -19s linear infinite;
  }
  @keyframes fall-148 {
    53.319% {
      transform: translate(73.2848vw, 53.319vh) scale(0.7863);
    }
    to {
      transform: translate(76.78795vw, 150vh) scale(0.7863);
    }
  }
  .snow:nth-child(149) {
    opacity: 0.8538;
    transform: translate(38.0684vw, -10px) scale(0.7314);
    animation: fall-149 17s -23s linear infinite;
  }
  @keyframes fall-149 {
    78.633% {
      transform: translate(44.3407vw, 78.633vh) scale(0.7314);
    }
    to {
      transform: translate(41.20455vw, 150vh) scale(0.7314);
    }
  }
  .snow:nth-child(150) {
    opacity: 0.1531;
    transform: translate(37.3017vw, -10px) scale(0.1504);
    animation: fall-150 20s -26s linear infinite;
  }
  @keyframes fall-150 {
    31.113% {
      transform: translate(43.7078vw, 31.113vh) scale(0.1504);
    }
    to {
      transform: translate(40.50475vw, 150vh) scale(0.1504);
    }
  }
  .snow:nth-child(151) {
    opacity: 0.8722;
    transform: translate(91.9396vw, -10px) scale(0.0044);
    animation: fall-151 28s -20s linear infinite;
  }
  @keyframes fall-151 {
    43.603% {
      transform: translate(91.116vw, 43.603vh) scale(0.0044);
    }
    to {
      transform: translate(91.5278vw, 150vh) scale(0.0044);
    }
  }
  .snow:nth-child(152) {
    opacity: 0.0515;
    transform: translate(19.8939vw, -10px) scale(0.6684);
    animation: fall-152 28s -8s linear infinite;
  }
  @keyframes fall-152 {
    75.713% {
      transform: translate(25.0569vw, 75.713vh) scale(0.6684);
    }
    to {
      transform: translate(22.4754vw, 150vh) scale(0.6684);
    }
  }
  .snow:nth-child(153) {
    opacity: 0.8442;
    transform: translate(46.371vw, -10px) scale(0.798);
    animation: fall-153 30s -6s linear infinite;
  }
  @keyframes fall-153 {
    58.616% {
      transform: translate(41.7506vw, 58.616vh) scale(0.798);
    }
    to {
      transform: translate(44.0608vw, 150vh) scale(0.798);
    }
  }
  .snow:nth-child(154) {
    opacity: 0.1018;
    transform: translate(71.914vw, -10px) scale(0.6719);
    animation: fall-154 29s -5s linear infinite;
  }
  @keyframes fall-154 {
    57.744% {
      transform: translate(75.5014vw, 57.744vh) scale(0.6719);
    }
    to {
      transform: translate(73.7077vw, 150vh) scale(0.6719);
    }
  }
  .snow:nth-child(155) {
    opacity: 0.7877;
    transform: translate(6.6174vw, -10px) scale(0.1465);
    animation: fall-155 27s -1s linear infinite;
  }
  @keyframes fall-155 {
    34.019% {
      transform: translate(7.87vw, 34.019vh) scale(0.1465);
    }
    to {
      transform: translate(7.2437vw, 150vh) scale(0.1465);
    }
  }
  .snow:nth-child(156) {
    opacity: 0.0823;
    transform: translate(84.9517vw, -10px) scale(0.252);
    animation: fall-156 22s -16s linear infinite;
  }
  @keyframes fall-156 {
    69.189% {
      transform: translate(79.7376vw, 69.189vh) scale(0.252);
    }
    to {
      transform: translate(82.34465vw, 150vh) scale(0.252);
    }
  }
  .snow:nth-child(157) {
    opacity: 0.5158;
    transform: translate(81.6858vw, -10px) scale(0.1445);
    animation: fall-157 11s -10s linear infinite;
  }
  @keyframes fall-157 {
    60.542% {
      transform: translate(81.4995vw, 60.542vh) scale(0.1445);
    }
    to {
      transform: translate(81.59265vw, 150vh) scale(0.1445);
    }
  }
  .snow:nth-child(158) {
    opacity: 0.8579;
    transform: translate(28.5712vw, -10px) scale(0.2678);
    animation: fall-158 10s -8s linear infinite;
  }
  @keyframes fall-158 {
    55.26% {
      transform: translate(35.907vw, 55.26vh) scale(0.2678);
    }
    to {
      transform: translate(32.2391vw, 150vh) scale(0.2678);
    }
  }
  .snow:nth-child(159) {
    opacity: 0.7573;
    transform: translate(67.3108vw, -10px) scale(0.4085);
    animation: fall-159 25s -19s linear infinite;
  }
  @keyframes fall-159 {
    35.35% {
      transform: translate(63.9282vw, 35.35vh) scale(0.4085);
    }
    to {
      transform: translate(65.6195vw, 150vh) scale(0.4085);
    }
  }
  .snow:nth-child(160) {
    opacity: 0.0876;
    transform: translate(24.8646vw, -10px) scale(0.3773);
    animation: fall-160 27s -30s linear infinite;
  }
  @keyframes fall-160 {
    34.17% {
      transform: translate(34.5235vw, 34.17vh) scale(0.3773);
    }
    to {
      transform: translate(29.69405vw, 150vh) scale(0.3773);
    }
  }
  .snow:nth-child(161) {
    opacity: 0.0379;
    transform: translate(65.9665vw, -10px) scale(0.2408);
    animation: fall-161 21s -20s linear infinite;
  }
  @keyframes fall-161 {
    60.909% {
      transform: translate(61.5842vw, 60.909vh) scale(0.2408);
    }
    to {
      transform: translate(63.77535vw, 150vh) scale(0.2408);
    }
  }
  .snow:nth-child(162) {
    opacity: 0.0837;
    transform: translate(56.2788vw, -10px) scale(0.1306);
    animation: fall-162 28s -13s linear infinite;
  }
  @keyframes fall-162 {
    64.274% {
      transform: translate(61.3539vw, 64.274vh) scale(0.1306);
    }
    to {
      transform: translate(58.81635vw, 150vh) scale(0.1306);
    }
  }
  .snow:nth-child(163) {
    opacity: 0.8771;
    transform: translate(63.0696vw, -10px) scale(0.6597);
    animation: fall-163 21s -18s linear infinite;
  }
  @keyframes fall-163 {
    39.53% {
      transform: translate(59.5785vw, 39.53vh) scale(0.6597);
    }
    to {
      transform: translate(61.32405vw, 150vh) scale(0.6597);
    }
  }
  .snow:nth-child(164) {
    opacity: 0.9999;
    transform: translate(4.2825vw, -10px) scale(0.0717);
    animation: fall-164 14s -13s linear infinite;
  }
  @keyframes fall-164 {
    79.762% {
      transform: translate(-3.9735vw, 79.762vh) scale(0.0717);
    }
    to {
      transform: translate(0.1545vw, 150vh) scale(0.0717);
    }
  }
  .snow:nth-child(165) {
    opacity: 0.4714;
    transform: translate(99.4958vw, -10px) scale(0.3269);
    animation: fall-165 21s -21s linear infinite;
  }
  @keyframes fall-165 {
    39.387% {
      transform: translate(105.6757vw, 39.387vh) scale(0.3269);
    }
    to {
      transform: translate(102.58575vw, 150vh) scale(0.3269);
    }
  }
  .snow:nth-child(166) {
    opacity: 0.3746;
    transform: translate(53.0239vw, -10px) scale(0.3657);
    animation: fall-166 12s -24s linear infinite;
  }
  @keyframes fall-166 {
    70.631% {
      transform: translate(62.7074vw, 70.631vh) scale(0.3657);
    }
    to {
      transform: translate(57.86565vw, 150vh) scale(0.3657);
    }
  }
  .snow:nth-child(167) {
    opacity: 0.5709;
    transform: translate(79.2712vw, -10px) scale(0.4085);
    animation: fall-167 30s -15s linear infinite;
  }
  @keyframes fall-167 {
    46.51% {
      transform: translate(72.7296vw, 46.51vh) scale(0.4085);
    }
    to {
      transform: translate(76.0004vw, 150vh) scale(0.4085);
    }
  }
  .snow:nth-child(168) {
    opacity: 0.4179;
    transform: translate(36.2152vw, -10px) scale(0.1466);
    animation: fall-168 14s -6s linear infinite;
  }
  @keyframes fall-168 {
    76.242% {
      transform: translate(33.3566vw, 76.242vh) scale(0.1466);
    }
    to {
      transform: translate(34.7859vw, 150vh) scale(0.1466);
    }
  }
  .snow:nth-child(169) {
    opacity: 0.5992;
    transform: translate(59.5258vw, -10px) scale(0.1451);
    animation: fall-169 26s -21s linear infinite;
  }
  @keyframes fall-169 {
    75.926% {
      transform: translate(59.2684vw, 75.926vh) scale(0.1451);
    }
    to {
      transform: translate(59.3971vw, 150vh) scale(0.1451);
    }
  }
  .snow:nth-child(170) {
    opacity: 0.4882;
    transform: translate(69.8301vw, -10px) scale(0.6129);
    animation: fall-170 15s -17s linear infinite;
  }
  @keyframes fall-170 {
    48.217% {
      transform: translate(74.4212vw, 48.217vh) scale(0.6129);
    }
    to {
      transform: translate(72.12565vw, 150vh) scale(0.6129);
    }
  }
  .snow:nth-child(171) {
    opacity: 0.5528;
    transform: translate(38.7188vw, -10px) scale(0.0332);
    animation: fall-171 28s -17s linear infinite;
  }
  @keyframes fall-171 {
    44.117% {
      transform: translate(48.4211vw, 44.117vh) scale(0.0332);
    }
    to {
      transform: translate(43.56995vw, 150vh) scale(0.0332);
    }
  }
  .snow:nth-child(172) {
    opacity: 0.5346;
    transform: translate(52.394vw, -10px) scale(0.3339);
    animation: fall-172 25s -28s linear infinite;
  }
  @keyframes fall-172 {
    36.243% {
      transform: translate(57.8666vw, 36.243vh) scale(0.3339);
    }
    to {
      transform: translate(55.1303vw, 150vh) scale(0.3339);
    }
  }
  .snow:nth-child(173) {
    opacity: 0.8248;
    transform: translate(37.0368vw, -10px) scale(0.9866);
    animation: fall-173 29s -29s linear infinite;
  }
  @keyframes fall-173 {
    46.597% {
      transform: translate(31.2186vw, 46.597vh) scale(0.9866);
    }
    to {
      transform: translate(34.1277vw, 150vh) scale(0.9866);
    }
  }
  .snow:nth-child(174) {
    opacity: 0.6638;
    transform: translate(12.2055vw, -10px) scale(0.0138);
    animation: fall-174 22s -1s linear infinite;
  }
  @keyframes fall-174 {
    58.418% {
      transform: translate(9.5348vw, 58.418vh) scale(0.0138);
    }
    to {
      transform: translate(10.87015vw, 150vh) scale(0.0138);
    }
  }
  .snow:nth-child(175) {
    opacity: 0.179;
    transform: translate(86.1vw, -10px) scale(0.4803);
    animation: fall-175 16s -13s linear infinite;
  }
  @keyframes fall-175 {
    75.752% {
      transform: translate(89.5932vw, 75.752vh) scale(0.4803);
    }
    to {
      transform: translate(87.8466vw, 150vh) scale(0.4803);
    }
  }
  .snow:nth-child(176) {
    opacity: 0.2015;
    transform: translate(36.3738vw, -10px) scale(0.805);
    animation: fall-176 12s -15s linear infinite;
  }
  @keyframes fall-176 {
    36.014% {
      transform: translate(41.4468vw, 36.014vh) scale(0.805);
    }
    to {
      transform: translate(38.9103vw, 150vh) scale(0.805);
    }
  }
  .snow:nth-child(177) {
    opacity: 0.4943;
    transform: translate(47.0746vw, -10px) scale(0.4928);
    animation: fall-177 28s -8s linear infinite;
  }
  @keyframes fall-177 {
    47.345% {
      transform: translate(42.3207vw, 47.345vh) scale(0.4928);
    }
    to {
      transform: translate(44.69765vw, 150vh) scale(0.4928);
    }
  }
  .snow:nth-child(178) {
    opacity: 0.9426;
    transform: translate(99.3236vw, -10px) scale(0.2999);
    animation: fall-178 19s -3s linear infinite;
  }
  @keyframes fall-178 {
    48.536% {
      transform: translate(90.5529vw, 48.536vh) scale(0.2999);
    }
    to {
      transform: translate(94.93825vw, 150vh) scale(0.2999);
    }
  }
  .snow:nth-child(179) {
    opacity: 0.6399;
    transform: translate(15.7813vw, -10px) scale(0.7856);
    animation: fall-179 19s -20s linear infinite;
  }
  @keyframes fall-179 {
    30.132% {
      transform: translate(18.9399vw, 30.132vh) scale(0.7856);
    }
    to {
      transform: translate(17.3606vw, 150vh) scale(0.7856);
    }
  }
  .snow:nth-child(180) {
    opacity: 0.8503;
    transform: translate(10.9591vw, -10px) scale(0.7916);
    animation: fall-180 12s -7s linear infinite;
  }
  @keyframes fall-180 {
    36.703% {
      transform: translate(9.1547vw, 36.703vh) scale(0.7916);
    }
    to {
      transform: translate(10.0569vw, 150vh) scale(0.7916);
    }
  }
  .snow:nth-child(181) {
    opacity: 0.1348;
    transform: translate(53.1461vw, -10px) scale(0.8013);
    animation: fall-181 22s -19s linear infinite;
  }
  @keyframes fall-181 {
    50.387% {
      transform: translate(59.4317vw, 50.387vh) scale(0.8013);
    }
    to {
      transform: translate(56.2889vw, 150vh) scale(0.8013);
    }
  }
  .snow:nth-child(182) {
    opacity: 0.9543;
    transform: translate(58.2148vw, -10px) scale(0.0388);
    animation: fall-182 15s -14s linear infinite;
  }
  @keyframes fall-182 {
    75.531% {
      transform: translate(62.9471vw, 75.531vh) scale(0.0388);
    }
    to {
      transform: translate(60.58095vw, 150vh) scale(0.0388);
    }
  }
  .snow:nth-child(183) {
    opacity: 0.5708;
    transform: translate(82.0553vw, -10px) scale(0.485);
    animation: fall-183 10s -6s linear infinite;
  }
  @keyframes fall-183 {
    31.738% {
      transform: translate(76.427vw, 31.738vh) scale(0.485);
    }
    to {
      transform: translate(79.24115vw, 150vh) scale(0.485);
    }
  }
  .snow:nth-child(184) {
    opacity: 0.0712;
    transform: translate(0.9395vw, -10px) scale(0.6482);
    animation: fall-184 26s -7s linear infinite;
  }
  @keyframes fall-184 {
    48.022% {
      transform: translate(-4.1316vw, 48.022vh) scale(0.6482);
    }
    to {
      transform: translate(-1.59605vw, 150vh) scale(0.6482);
    }
  }
  .snow:nth-child(185) {
    opacity: 0.4314;
    transform: translate(73.6599vw, -10px) scale(0.9771);
    animation: fall-185 26s -21s linear infinite;
  }
  @keyframes fall-185 {
    31.231% {
      transform: translate(71.9023vw, 31.231vh) scale(0.9771);
    }
    to {
      transform: translate(72.7811vw, 150vh) scale(0.9771);
    }
  }
  .snow:nth-child(186) {
    opacity: 0.1247;
    transform: translate(17.9018vw, -10px) scale(0.482);
    animation: fall-186 17s -23s linear infinite;
  }
  @keyframes fall-186 {
    48.965% {
      transform: translate(21.7857vw, 48.965vh) scale(0.482);
    }
    to {
      transform: translate(19.84375vw, 150vh) scale(0.482);
    }
  }
  .snow:nth-child(187) {
    opacity: 0.6122;
    transform: translate(79.2544vw, -10px) scale(0.022);
    animation: fall-187 13s -18s linear infinite;
  }
  @keyframes fall-187 {
    57.741% {
      transform: translate(87.3109vw, 57.741vh) scale(0.022);
    }
    to {
      transform: translate(83.28265vw, 150vh) scale(0.022);
    }
  }
  .snow:nth-child(188) {
    opacity: 0.7029;
    transform: translate(54.5522vw, -10px) scale(0.7017);
    animation: fall-188 23s -23s linear infinite;
  }
  @keyframes fall-188 {
    73.241% {
      transform: translate(59.5396vw, 73.241vh) scale(0.7017);
    }
    to {
      transform: translate(57.0459vw, 150vh) scale(0.7017);
    }
  }
  .snow:nth-child(189) {
    opacity: 0.3526;
    transform: translate(75.9194vw, -10px) scale(0.6914);
    animation: fall-189 14s -15s linear infinite;
  }
  @keyframes fall-189 {
    35.731% {
      transform: translate(70.0908vw, 35.731vh) scale(0.6914);
    }
    to {
      transform: translate(73.0051vw, 150vh) scale(0.6914);
    }
  }
  .snow:nth-child(190) {
    opacity: 0.341;
    transform: translate(8.7055vw, -10px) scale(0.0739);
    animation: fall-190 25s -3s linear infinite;
  }
  @keyframes fall-190 {
    62.843% {
      transform: translate(9.0326vw, 62.843vh) scale(0.0739);
    }
    to {
      transform: translate(8.86905vw, 150vh) scale(0.0739);
    }
  }
  .snow:nth-child(191) {
    opacity: 0.2226;
    transform: translate(21.6214vw, -10px) scale(0.1503);
    animation: fall-191 24s -20s linear infinite;
  }
  @keyframes fall-191 {
    63.759% {
      transform: translate(27.6373vw, 63.759vh) scale(0.1503);
    }
    to {
      transform: translate(24.62935vw, 150vh) scale(0.1503);
    }
  }
  .snow:nth-child(192) {
    opacity: 0.2353;
    transform: translate(68.7895vw, -10px) scale(0.9739);
    animation: fall-192 15s -4s linear infinite;
  }
  @keyframes fall-192 {
    72.208% {
      transform: translate(65.2812vw, 72.208vh) scale(0.9739);
    }
    to {
      transform: translate(67.03535vw, 150vh) scale(0.9739);
    }
  }
  .snow:nth-child(193) {
    opacity: 0.7317;
    transform: translate(45.7966vw, -10px) scale(0.5618);
    animation: fall-193 29s -20s linear infinite;
  }
  @keyframes fall-193 {
    38.177% {
      transform: translate(48.9203vw, 38.177vh) scale(0.5618);
    }
    to {
      transform: translate(47.35845vw, 150vh) scale(0.5618);
    }
  }
  .snow:nth-child(194) {
    opacity: 0.1465;
    transform: translate(49.8403vw, -10px) scale(0.8306);
    animation: fall-194 14s -18s linear infinite;
  }
  @keyframes fall-194 {
    65.705% {
      transform: translate(56.8028vw, 65.705vh) scale(0.8306);
    }
    to {
      transform: translate(53.32155vw, 150vh) scale(0.8306);
    }
  }
  .snow:nth-child(195) {
    opacity: 0.2971;
    transform: translate(91.1086vw, -10px) scale(0.1813);
    animation: fall-195 17s -25s linear infinite;
  }
  @keyframes fall-195 {
    66.937% {
      transform: translate(85.2615vw, 66.937vh) scale(0.1813);
    }
    to {
      transform: translate(88.18505vw, 150vh) scale(0.1813);
    }
  }
  .snow:nth-child(196) {
    opacity: 0.8214;
    transform: translate(87.2935vw, -10px) scale(0.7893);
    animation: fall-196 10s -19s linear infinite;
  }
  @keyframes fall-196 {
    73.935% {
      transform: translate(94.7506vw, 73.935vh) scale(0.7893);
    }
    to {
      transform: translate(91.02205vw, 150vh) scale(0.7893);
    }
  }
  .snow:nth-child(197) {
    opacity: 0.1808;
    transform: translate(72.9409vw, -10px) scale(0.5116);
    animation: fall-197 23s -16s linear infinite;
  }
  @keyframes fall-197 {
    74.228% {
      transform: translate(67.1071vw, 74.228vh) scale(0.5116);
    }
    to {
      transform: translate(70.024vw, 150vh) scale(0.5116);
    }
  }
  .snow:nth-child(198) {
    opacity: 0.6675;
    transform: translate(9.2003vw, -10px) scale(0.7263);
    animation: fall-198 18s -25s linear infinite;
  }
  @keyframes fall-198 {
    58.882% {
      transform: translate(7.7747vw, 58.882vh) scale(0.7263);
    }
    to {
      transform: translate(8.4875vw, 150vh) scale(0.7263);
    }
  }
  .snow:nth-child(199) {
    opacity: 0.9878;
    transform: translate(6.1047vw, -10px) scale(0.4389);
    animation: fall-199 24s -10s linear infinite;
  }
  @keyframes fall-199 {
    76.544% {
      transform: translate(15.6042vw, 76.544vh) scale(0.4389);
    }
    to {
      transform: translate(10.85445vw, 150vh) scale(0.4389);
    }
  }
  .snow:nth-child(200) {
    opacity: 0.7186;
    transform: translate(74.5255vw, -10px) scale(0.2784);
    animation: fall-200 12s -4s linear infinite;
  }
  @keyframes fall-200 {
    63.902% {
      transform: translate(81.9179vw, 63.902vh) scale(0.2784);
    }
    to {
      transform: translate(78.2217vw, 150vh) scale(0.2784);
    }
  }
`

const SnowLayer = () => {
  return <SnowWrapper>
    <div className="canvas">
      {
        [...Array(200).keys()].map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="snow" key={`snow-${index}`} />
        ))
      }
    </div>
  </SnowWrapper>
}

export default SnowLayer
