import React, { useMemo } from 'react'
import { ModalProvider } from 'archerswap-uikit'
import { Web3ReactProvider, Web3ReactHooks } from '@web3-react/core'
import { Connector } from '@web3-react/types';
import { Provider } from 'react-redux'
// import { getLibrary } from 'utils/web3React'
import { LanguageProvider } from 'contexts/Localization'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { TokenCreationContextProvider } from 'contexts/TokenCreationContext'
import store from 'state'
import useOrderedConnections from 'hooks/useOrderedConnections';
// import { NetworkContextName } from './constants'

const Providers: React.FC = ({ children }) => {
  const connections = useOrderedConnections();

  const connectors: [
    Connector,
    Web3ReactHooks,
  ][] = connections.map(({ hooks, connector }) => [connector, hooks]);

  const key = useMemo(
    () => connections.map((connection) => connection.name).join('-'),
    [connections],
  );

  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      <Provider store={store}>
        <ThemeContextProvider>
          <LanguageProvider>
            <RefreshContextProvider>
              <TokenCreationContextProvider>
                <ModalProvider>{children}</ModalProvider>
              </TokenCreationContextProvider>
            </RefreshContextProvider>
          </LanguageProvider>
        </ThemeContextProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers
