import { useEffect } from 'react'
import { connectorLocalStorageKey, ConnectorNames } from 'archerswap-uikit'
import useAuth from 'hooks/useAuth'
import {
  metamaskConnection,
  walletConnectConnection,
  okxWalletConnection
} from 'connectors';

const useEagerConnect = () => {
  const { login } = useAuth()

  const tryConnect = async (selectedConnection: any) => {
    if (!selectedConnection) {
      return
    }

    if (selectedConnection?.connectEagerly) {
      await selectedConnection.connectEagerly();
    } else {
      await selectedConnection.activate()
    }
  }

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames

    // Disable eager connect for Core Chain Wallet. Currently the Core Chain Wallet extension does not inject BinanceChain
    // into the Window object in time causing it to throw an error
    // TODO: Figure out an elegant way to listen for when the BinanceChain object is ready
    // if (connectorId) {
    //   login(connectorId)
    // }
    let selectedConnection: any;
    if (connectorId === ConnectorNames.Injected) {
      selectedConnection = metamaskConnection;
    } else if (connectorId === ConnectorNames.WalletConnect) {
      selectedConnection = walletConnectConnection;
    } else if (connectorId === ConnectorNames.OkxWallet) {
      selectedConnection = okxWalletConnection;
    }

    tryConnect(selectedConnection?.connector)
  }, [login])
}

export default useEagerConnect
