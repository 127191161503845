/* eslint-disable import/no-unresolved, import/prefer-default-export */
import { QrModalOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider';
import { WalletConnect, WalletConnectConstructorArgs } from './WalletConnect';
import { rpcMap } from './providers';

export class WalletConnectPopup extends WalletConnect {
  constructor({
    actions,
    onError,
    qrcode = true,
    qrModalOptions,
  }: Omit<WalletConnectConstructorArgs, 'options'> & {
    qrcode?: boolean;
    qrModalOptions?: QrModalOptions;
  }) {
    super({
      actions,
      options: {
        showQrModal: qrcode,
        chains: [1116],
        projectId: '41abfb2d50aad725c4623d0324f59d83',
        qrModalOptions,
        rpcMap
      },
      onError,
    });
  }

  activate(chainId?: number) {
    return super.activate(chainId);
  }
}
