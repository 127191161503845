import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ConnectorNames } from 'archerswap-uikit'
import { useToast } from 'state/hooks'
import {
  Connection,
  metamaskConnection,
  walletConnectConnection,
  okxWalletConnection
} from 'connectors';
import { WalletConnect } from 'connectors/WalletConnect';
import { NoOkxWalletError } from 'connectors/OkxWallet';

const useAuth = () => {
  const { chainId, connector } = useWeb3React();
  const { toastError, toastInfo } = useToast()

  const tryActivation = async (connection: Connection) => {
    if (
      chainId &&
      (connection.name === "WALLET_CONNECT" ||
        connection.name === "ZENGO_CONNECT")
    ) {
      (connection.connector as WalletConnect).setRequiredChains([chainId]);
    }

    try {
      if (connector.deactivate) {
        await connector.deactivate();
      }
      await connector.resetState();
      await connection.connector.activate(1116);
    } catch (e) {
      if (e instanceof NoOkxWalletError) {
        toastInfo('Provider Error', 'OkxWallet not installed', { text: "Install OKX Wallet", url: "https://www.okx.com/web3" })
      } else {
        toastError('Provider Error', 'No provider was found')
      }
    }
  };

  const login = useCallback(async (connectorID: ConnectorNames) => {
    if (connectorID === ConnectorNames.Injected) {
      tryActivation(metamaskConnection);
    } else if (connectorID === ConnectorNames.WalletConnect) {
      tryActivation(walletConnectConnection);
    } else if (connectorID === ConnectorNames.OkxWallet) {
      tryActivation(okxWalletConnection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(async () => {
    if (connector.deactivate) {
      await connector.deactivate();
    }
    if (connector) {
      await connector.resetState();
    }
  }, [connector])

  return { login, logout }
}

export default useAuth
