import { ConnectionType, networkConnection } from 'connectors';
import { useGetConnection } from 'hooks';
// import { useMemo } from 'react';
// import { useAppSelector } from 'state/hooks';

const SELECTABLE_WALLETS = [
  ConnectionType.METAMASK,
  ConnectionType.WALLET_CONNECT,
  // ConnectionType.COINBASE_WALLET,
  // ConnectionType.ARKANE,
  // ConnectionType.PHATOM,
  // ConnectionType.TRUSTWALLET,
  // ConnectionType.BITGET,
  // ConnectionType.BLOCKWALLET,
  // ConnectionType.BRAVEWALLET,
  // ConnectionType.CYPHERD,
  ConnectionType.OKXWALLET,
];

export default function useOrderedConnections() {
  // const selectedWallet = useAppSelector((state) => state.user.selectedWallet);
  const getConnection = useGetConnection();
  const orderedConnectionTypes: ConnectionType[] = [];

  orderedConnectionTypes.push(ConnectionType.NETWORK);

  orderedConnectionTypes.push(
    ...SELECTABLE_WALLETS.filter((wallet) => wallet !== null),
  );

  return orderedConnectionTypes.map(
    (connectionType) => getConnection(connectionType) ?? networkConnection,
  );
}
